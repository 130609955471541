import axios from "axios";
import API from "../services/services";

const REGISTER_API = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL1,
});

class BloodvanService {
  getAll(params) {
    return API.get("manage_blood_van/list", { params });
  }

  getBloodvanDetail(id) {
    return API.get(`manage_blood_van/detail/${id}`);
  }

  addBloodvan({
    name,
    service,
    address,
    contact,
    location,
    latitude,
    longitude,
    description,
    pic,
  }) {
    return API.post(`manage_blood_van/list/store`, {
      name: name,
      service: service,
      address: address,
      contact: contact,
      location: location,
      latitude: latitude,
      longitude: longitude,
      description: description,
      pic: pic,
    });
  }

  updateBloodvan({
    id,
    name,
    service,
    address,
    latitude,
    longitude,
    is_active,
    contact,
    description,
    pic,
    location,
  }) {
    return API.post(`manage_blood_van/list/update`, {
      id: id,
      name: name,
      service: service,
      address: address,
      latitude: latitude,
      longitude: longitude,
      is_active: is_active,
      contact: contact,
      description: description,
      pic: pic,
      location: location,
    });
  }

  getSchedule(params, token) {
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return REGISTER_API.get(
      `mobile/registration/list_schedule?${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  addSchedule(payload) {
    return API.post(`manage_blood_van/list/schedule/create`, payload);
  }

  editSchedule(payload) {
    return API.post(`manage_blood_van/list/schedule/update`, payload);
  }

  deleteBloodvan(id) {
    return API.post(`manage_blood_van/list/delete`, { id });
  }

  listSchedule(params) {
    return API.get(`manage_blood_van/list/schedule`, { params });
  }
}

export default new BloodvanService();
