import api from "../api";

const URLS = {
    fetchDonations: 'dashboard/count_donation',
    fetchBloodVans: 'dashboard/count_blood_van',
    fetchBloodbags: 'dashboard/count_blood_bag',
    fetchHospitals: 'dashboard/count_hospital'
};

const token = localStorage.getItem('token');

export const fetchDonations = () => {
    return api.get(URLS.fetchDonations, {
        baseURL: process.env.VUE_APP_VARIABLES,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
};

export const fetchBloodvans = () => {
    return api.get(URLS.fetchBloodVans, {
        baseURL: process.env.VUE_APP_VARIABLES,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
};

export const fetchBloodbags = () => {
    return api.get(URLS.fetchBloodbags, {
        baseURL: process.env.VUE_APP_VARIABLES,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
};

export const fetchHospitals = () => {
    return api.get(URLS.fetchHospitals, {
        baseURL: process.env.VUE_APP_VARIABLES,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
};
