<template>
  <b-card class="hp-last-registration-table-card h-100 overflow-auto">
    <div class="d-flex align-items-center justify-content-between mb-32">
      <h5 class="mb-0">Status Mobil Unit</h5>
    </div>

    <div style="overflow: auto; max-height: 500px">
      <b-table
        class="align-middle mb-0 table table-small"
        responsive
        :items="tableData"
        :fields="[
          { key: 'name', label: 'Nama Mobil Unit' },
          { key: 'is_active', label: 'Status' },
        ]"
      >
        <template #cell(is_active)="row">
          <b-badge :variant="row.item.is_active === 1 ? 'success' : 'danger'">
            {{ row.item.is_active === 1 ? "Aktif" : "Nonaktif" }}
          </b-badge>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import { BCard, BBadge, BTable } from "bootstrap-vue";
import BloodvanService from "../../../../api/managebloodvan/manageBloodvanAPI";

export default {
  name: "TableInfoBloodvan",
  components: {
    BCard,
    BTable,
    BBadge,
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    getAllBloodvan() {
      BloodvanService.getAll()
        .then((response) => {
          this.tableData = response.data.data.data;
        })
        .catch((err) => console.error(err));
    },
  },
  mounted() {
    this.getAllBloodvan();
  },
};
</script>

<style scoped></style>
