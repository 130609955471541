import axios from "axios";
import API from "../services/services";
const MOBILE_API = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_MOBILE,
});

class AktivasiPendonorService {
  getAll(params) {
    return API.get("manage_registration/list_registration", { params });
  }

  getWaitingList(params) {
    return API.get("manage_registration/waiting_list", { params });
  }

  getPendonor(id) {
    return API.get(`manage_registration/detail_registration/${id}`);
  }

  updateAdministration({
    id,
    administrator_id,
    validation,
    validation_number,
    prev_history,
    last_donor_date,
    donor_type,
    donor_times,
    os,
    rs,
  }) {
    return API.post("manage_registration/update_administration", {
      id,
      administrator_id,
      validation,
      validation_number,
      prev_history,
      last_donor_date,
      donor_type,
      donor_times,
      os,
      rs,
    });
  }

  addRegistrationQuestion(formData) {
    return MOBILE_API.post(`registration/list_questionnaire/store`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  updateRegistrationQuestion(formData) {
    return API.post(
      `manage_registration/list_registration_question/update`,
      formData
    );
  }

  rejectRegistration(id, reason_rejected, note) {
    return API.post("manage_registration/reject", {
      id: id,
      reason_rejected: reason_rejected,
      note: note,
    });
  }

  listRegistrationQuestionnaire(id, params) {
    return API.get(`manage_registration/list_registration_question/${id}`, {
      params,
    });
  }
}

export default new AktivasiPendonorService();
