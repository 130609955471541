<template>
  <div class="d-flex align-items-center justify-content-around">
    <b-card class="hp-card-6 hp-order-table-card w-100">
      <h5 class="mb-8">Jadwal MU Terkini</h5>
      <div class="w-100">
        <div v-if="isLoading">
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table
                :rows="6"
                :columns="7"
                :table-props="{ bordered: true, striped: true }"
              />
            </template>
          </b-skeleton-wrapper>
        </div>

        <FullCalendar
          v-if="!isLoading"
          ref="fullCalendar"
          :options="calendarOptions"
          class="w-100 custom-calendar"
        />

        <div v-if="isLoading">
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton width="70%" />
              <b-skeleton width="55%" />
            </template>
          </b-skeleton-wrapper>
        </div>
      </div>
    </b-card>
    <BaseModal>
      <template #header>
        <h5 class="mb-0">Info Detail Mobil Unit</h5>
      </template>

      <div v-if="!isLoading" class="mx-auto mt-0" style="max-width: 700px">
        <template v-if="selectedEvent">
          <h4 class="mb-3">
            Acara MU pada tanggal {{ formatDate(selectedEvent.start) }}
          </h4>
          <h5 class="mb-3">
            {{ selectedEvent.title }}
          </h5>
          <div class="row">
            <div class="col-md-4 col-sm-5 mb-2">
              <p class="mb-2">
                <strong class="mr-2">📌</strong><strong>Lokasi MU:</strong>
              </p>
              <p class="mb-2">
                <strong class="mr-2">🗺</strong><strong>Alamat:</strong>
              </p>
              <p class="mb-2" style="margin-top: 25px">
                <strong class="mr-2">📞</strong><strong>Kontak MU:</strong>
              </p>
              <p class="mb-2">
                <strong class="mr-2">⏰</strong
                ><strong>Waktu <br />Pelaksanaan:</strong>
              </p>
            </div>
            <div class="col-md-8 col-sm-7">
              <p class="mb-2">
                {{ selectedEvent.extendedProps.location }}
              </p>
              <p class="mb-2">
                {{ selectedEvent.extendedProps.address }}
              </p>
              <p class="mb-2">
                {{ selectedEvent.extendedProps.contact || "-" }}
              </p>
              <p class="mb-2">
                {{ formatEventTime(selectedEvent) }}
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <h4>Jadwal MU Tidak Tersedia</h4>
        </template>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import idLocale from "@fullcalendar/core/locales/id";
import BloodvanService from "@/api/managebloodvan/manageBloodvanAPI";
import {
  BCard,
  BSkeletonWrapper,
  BSkeletonTable,
  BSkeleton,
} from "bootstrap-vue";

export default {
  name: "CalendarViewMobileUnit",
  components: {
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,
    FullCalendar,
    BCard,
  },
  props: ["isLoading"],
  data() {
    return {
      selectedDate: "",
      selectedEvent: null,
      events: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        timeZone: "UTC",
        locale: idLocale,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        },
        eventClick: this.handleEventClick,
        events: this.events,
        // dayCellClassNames: this.getDayCellClass,
        dayCellDidMount: (cell) => {
          this.getDayCellClass(cell);
        },
      },
    };
  },
  created() {
    this.getAllBloodvan();
  },
  methods: {
    getAllBloodvan() {
      this.isLoading = true;

      BloodvanService.getAll()
        .then((response) => {
          this.processBloodvanData(response.data.data.data);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    processBloodvanData(data) {
      this.events = data.flatMap((event) => {
        const schedule = JSON.parse(event.schedule);
        return schedule.map((s) => ({
          title: event.name,
          start: `${s.date}T${s.open_hour}:${s.open_minute}`,
          end: `${s.date}T${s.close_hour}:${s.close_minute}`,
          extendedProps: {
            location: event.location,
            address: event.address,
            contact: event.contact,
          },
        }));
      });
      this.$refs.fullCalendar.getApi().removeAllEvents();
      this.$refs.fullCalendar.getApi().addEventSource(this.events);
    },
    handleEventClick(info) {
      this.selectedEvent = info.event;
      this.selectedDate = info.event.start;

      this.$bvModal.show("base-modal");
    },
    getDayCellClass(arg) {
      const date = arg.date.toISOString().split("T")[0];
      const today = new Date().toISOString().split("T")[0];

      if (date === today) {
        arg.el.classList.add("fc-day-today");
      }

      if (this.events.some((event) => event.start.startsWith(date))) {
        arg.el.classList.add("has-mu-schedule");
      }

      return ""; // Regular date
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(date);
    },
    formatEventTime(event) {
      const start = new Date(event.start);
      const end = new Date(event.end);
      const options = { hour: "numeric", minute: "numeric" };
      return `${this.formatDate(start)}, Pukul ${start.toLocaleTimeString(
        "id-ID",
        options
      )} s/d ${end.toLocaleTimeString("id-ID", options)}`;
    },
  },
};
</script>
<style scoped>
.fc-day-today {
  background-color: green !important; /* Light blue for today */
}

.has-mu-schedule {
  background-color: black !important; /* Light red for MU schedule */
}

.custom-calendar >>> .fc-header-toolbar {
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
}
</style>
