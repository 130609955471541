<template>
  <b-card class="hp-last-registration-table-card h-100 overflow-auto">
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-between mb-32">
          <h5 class="mb-0">Aktivasi Pendonor Terakhir</h5>
          <router-link to="/dashboards/donations">
            <p
              class="hp-p1-body mb-0 font-weight-medium text-black-100 hp-text-color-dark-0"
            >
              Lihat Semua
            </p>
          </router-link>
        </div>

        <b-table-simple borderless responsive class="align-middle mb-0">
          <b-thead>
            <b-tr>
              <b-th style="min-width: 110px">
                <span
                  class="hp-badge-size d-block pb-16 font-weight-normal text-black-60 hp-text-color-dark-50 text-uppercase"
                >
                  Tanggal
                </span>
              </b-th>
              <b-th style="min-width: 180px">
                <span
                  class="hp-badge-size d-block pb-16 font-weight-normal text-black-60 hp-text-color-dark-50 text-uppercase"
                >
                  Nama
                </span>
              </b-th>
              <b-th>
                <span
                  class="hp-badge-size d-block pb-16 font-weight-normal text-black-60 hp-text-color-dark-50 text-uppercase"
                >
                  Golongan Darah
                </span>
              </b-th>
              <b-th>
                <span
                  class="hp-badge-size d-block pb-16 font-weight-normal text-black-60 hp-text-color-dark-50 text-uppercase"
                >
                  Tempat Donor
                </span>
              </b-th>
              <b-th>
                <span
                  class="hp-badge-size d-block pb-16 font-weight-normal text-black-60 hp-text-color-dark-50 text-uppercase"
                >
                  Status
                </span>
              </b-th>
            </b-tr>
          </b-thead>

          <tbody>
            <b-tr :key="index" v-for="(item, index) in tableData">
              <b-td>
                <span class="mb-0 text-black-80 hp-text-color-dark-30">
                  {{ item.date }}
                </span>
              </b-td>
              <b-td class="ps-0">
                <span
                  class="mb-0 font-weight-medium text-black-100 hp-text-color-dark-0"
                >
                  {{ item.name }}
                </span>
              </b-td>
              <b-td class="ps-0">
                <span
                  class="mb-0 font-weight-medium text-black-100 hp-text-color-dark-0"
                >
                  {{ item.blood_type ?? "Belum tersedia" }}
                </span>
              </b-td>
              <b-td class="ps-0">
                <span
                  class="mb-0 font-weight-medium text-black-100 hp-text-color-dark-0"
                >
                  {{ item.location ?? "Belum tersedia" }}
                </span>
              </b-td>
              <b-td>
                <b-badge
                  :variant="
                    item.status === 'Approved' || item.status === 'Done'
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{
                    item.status === "Approved" || item.status === "Done"
                      ? "Diterima"
                      : "Ditolak"
                  }}
                </b-badge>
              </b-td>
            </b-tr>
          </tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BBadge,
} from "bootstrap-vue";
import AktivasiPendonorService from "../../../../api/managependonor/managePendonorAPI";

export default {
  name: "TableLatestRegistration",
  data() {
    return {
      tableData: null,
    };
  },
  methods: {
    getAllPendonor() {
      const params = {
        per_page: 5,
      };
      AktivasiPendonorService.getAll(params)
        .then((response) => {
          this.tableData = response.data.data.data;
        })
        .catch((err) => console.error(err));
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BBadge,
  },
  created() {
    this.getAllPendonor();
  },
};
</script>
