<template>
  <b-row class="mb-32">
    <b-col
      cols="12"
      class="mb-32"
    >
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col
          cols="12"
          md="6"
        >
          <h1 class="hp-mb-0">
            Dashboard
          </h1>
        </b-col>
        <b-col class="hp-flex-none w-auto">
          <b-dropdown
            v-model="selected"
            text="Pilih Informasi"
            variant="white"
            class="dropdown mb-3"
            @change="handleDropdownChange"
          >
            <b-dropdown-item
              v-for="option in options"
              :key="option.id"
              :value="option.value"
              :to="option.to"
              class="dropdown-item"
            >
              {{ option.label }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col class="hp-flex-none w-auto hp-dashboard-line px-0">
          <div
            class="hp-bg-black-40 hp-bg-dark-80 h-100 mx-24"
            style="width: 1px"
          />
        </b-col>
      </b-row>
      <!-- Analytics Info is default -->
      <AnalyticsDashboard
        :loading="loading"
        :bloodbag="bloodbag"
        :bloodvan="bloodvan"
        :hospital="hospital"
        :donation="donation"
      />
    </b-col>
  </b-row>

</template>

<script>
import {
  BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import AnalyticsDashboard from '@/view/main/dashboards/analytics/informationType/Analytics/AnalyticsDashboard.vue'

import ManageBloodvanAPI from '@/api/managebloodvan/manageBloodvanAPI'
import CalendarViewMobileUnit from '@/view/main/dashboards/analytics/CalendarViewMobileUnit.vue'
import {
  fetchDonations,
  fetchBloodvans,
  fetchBloodbags,
  fetchHospitals,
} from '../../../../api/dashboard/dashboardAPI'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    AnalyticsDashboard,
  },
  data() {
    return {
      loading: false,
      events: {},
      donation: null,
      bloodvan: null,
      bloodbag: null,
      hospital: null,
      selected: 'analytics',
      options: [
        {
          value: 'analytics',
          label: 'Informasi Umum',
          to: '/dashboards/analytics',
        },
        {
          value: 'stocks',
          label: 'Informasi Stok Darah',
          to: '/dashboards/stocks',
        },
        {
          value: 'finances',
          label: 'Informasi Keuangan',
          to: '/dashboards/finances',
        },
      ],
    }
  },
  watch: {
    selected() {
      this.handleDropdownChange()
    },
  },
  created() {
    this.fetchInfoDashboard()
  },
  methods: {
    async fetchInfoDashboard() {
      this.loading = true
      await Promise.all([
        this.fetchDonation(),
        this.fetchBloodVan(),
        this.fetchBloodBag(),
        this.fetchHospital(),
      ])
      this.loading = false
    },
    async fetchDonation() {
      const response = await fetchDonations()
      this.donation = response.data.data.donor_register
    },
    async fetchBloodVan() {
      const response = await fetchBloodvans()
      this.bloodvan = response.data.data.blood_van_total
    },
    async fetchBloodBag() {
      const response = await fetchBloodbags()
      this.bloodbag = response.data.data.bloog_bag_total
    },
    async fetchHospital() {
      const response = await fetchHospitals()
      this.hospital = response.data.data.hospital_total
    },
    handleDropdownChange() {
      const selectedRoute = this.options.find(
        option => option.value === this.selected,
      ).to
      this.$router.push(selectedRoute)
    },
  },
}
</script>

<style scoped>
.dropdown {
  font-size: 14px;
  background-color: white;
  width: 180px;
}
.dropdown-item {
  font-size: 14px;
  width: 167px;
  padding: 0px;
}
</style>
