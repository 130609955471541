<template>
  <div>
    <b-row>
      <b-col
        v-for="(item, index) in cards"
        :key="index"
        cols="12"
        md="6"
        xl="3"
        class="mb-32"
      >
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card>
              <b-row>
                <b-col class="hp-flex-none w-auto">
                  <b-skeleton
                    width="200px"
                    height="40px"
                    class="rounded-lg"
                  />
                </b-col>
                <b-col class="pl-0">
                  <b-skeleton
                    width="60%"
                    class="mb-2"
                  />
                  <b-skeleton width="40%" />
                </b-col>
              </b-row>
            </b-card>
          </template>
          <b-card>
            <b-row>
              <b-col class="hp-flex-none w-auto">
                <img
                    :src="item.icon"
                    class="d-flex align-items-center justify-content-center hp-dashboard-feature-card-icon rounded-lg"
                >
              </b-col>
              <b-col class="pl-0">
                <span
                    class="h3 mb-4 mt-8 d-block hp-text-color-black-bg hp-text-color-dark-0 font-weight-medium mr-4"
                >
                  {{ item.value }}
                </span>
                <p
                    class="hp-p1-body mb-0 font-weight-normal hp-text-color-black-100"
                >
                  {{ item.label }}
                </p>
              </b-col>
            </b-row>
          </b-card>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="8"
        class="mb-32"
      >
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="5"
              :table-props="{ bordered: true, striped: true }"
            />
          </template>
          <table-latest-registration v-if="!loading" />
        </b-skeleton-wrapper>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        class="mb-32"
      >
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="2"
              :table-props="{ bordered: true, striped: true }"
            />
          </template>
          <table-info-bloodvan v-if="!loading" />
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
    <calendar-view-mobile-unit :is-loading="loading"/>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BSkeleton,
  BSkeletonTable,
  BSkeletonWrapper,
} from 'bootstrap-vue'
import TableLatestRegistration from '@/view/main/dashboards/analytics/TableLatestRegistration.vue'
import TableInfoBloodvan from '@/view/main/dashboards/analytics/TableInfoBloodvan.vue'
import CalendarViewMobileUnit from "@/view/main/dashboards/analytics/CalendarViewMobileUnit.vue";

export default {
  name: 'DashboardsAnalytics',
  components: {
    CalendarViewMobileUnit,
    BSkeletonTable,
    TableInfoBloodvan,
    TableLatestRegistration,
    BCard,
    BCol,
    BRow,
    BSkeleton,
    BSkeletonWrapper,
  },
  props: ['donation', 'bloodvan', 'bloodbag', 'hospital', 'loading'],
  data() {
    return {
      cards: [
        {
          icon: require('@/assets/img/dashboard/card/people.svg'),
          value: this.donation,
          label: 'Pendonor',
        },
        {
          icon: require('@/assets/img/dashboard/card/car.svg'),
          value: this.bloodvan,
          label: 'Mobil Unit',
        },
        {
          icon: require('@/assets/img/dashboard/card/bag.svg'),
          value: this.bloodbag,
          label: 'Blood Bag',
        },
        {
          icon: require('@/assets/img/dashboard/card/hospital.svg'),
          value: this.hospital,
          label: 'Hospital',
        },
      ],
    }
  },
  watch: {
    donation(newValue) {
      this.updateCardValue('Pendonor', newValue)
    },
    bloodvan(newValue) {
      this.updateCardValue('Mobil Unit', newValue)
    },
    bloodbag(newValue) {
      this.updateCardValue('Blood Bag', newValue)
    },
    hospital(newValue) {
      this.updateCardValue('Hospital', newValue)
    },
  },
  methods: {
    updateCardValue(label, value) {
      const card = this.cards.find(card => card.label === label)
      if (card) {
        card.value = value
      }
    },
  },
}
</script>
